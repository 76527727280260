import {render} from "lit-html";
import {parseIntOrNull} from "../../utils/number.js";
import { ExtendedApiError } from "../../../components-shared/api/base/extended-api-error.js";
import { IExtendedApiResponse } from "../../../components-shared/api/base/i-extended-api-response.js";

declare var productGroupsRootPageId: number;
declare var BasePageID: number;

export class CmsMenu extends HTMLElement {
    root:number;
    viewPath:string;
    data: IMenuData;
    settings: IMenuSettings;
    viewRenderer: { main: (data: IMenuData, settings: IMenuSettings) => {} };

    constructor(){
        super();
    }

    async connectedCallback() {

        this.readAttributes();

        const rootItems = await this.loadRootData();

        this.viewRenderer = await import(this.viewPath) as { main: (data: IMenuData, settings: IMenuSettings) => {} };

        this.settings = {
            toggle: this.toggle.bind(this)
        };

        this.data = {
            children: rootItems
        };

        this.renderView();
    }

    renderView() {
        const renderedView = this.viewRenderer.main(this.data, this.settings);
        render(renderedView, this);
    }

    readAttributes() {
        if (this.hasAttribute("root")) {
            const rootValue = this.getAttribute("root");
            this.root = parseIntOrNull(rootValue) ?? 0;

            if (this.root === -2) {
                this.root = productGroupsRootPageId;
            }
        } else {
            this.root = BasePageID;
        }

        if (this.hasAttribute("view")) {
            this.viewPath = this.getAttribute("view");
        } else {
            this.viewPath = "./views/default.js";
        }
    }

    async loadRootData() {
        return this.loadChildren(this.root);
    }

    async loadChildren(id:number) : Promise<IMenuItem[]> {
        if (id < 0) {
            throw new Error("cms-menu: Failed to load children. ID is less than 0");
        }

        const response = await fetch(`/api/website/menu/${id}?rootPageId=${this.root}&currentPageId=${BasePageID}`);
        const dataResponse = await response.json() as IExtendedApiResponse<IMenuItem[]>;

        if (!dataResponse || dataResponse.hasError) {
            throw new ExtendedApiError(dataResponse);
        }

        return dataResponse.result;
    }

    async toggle(event: MouseEvent, item: IMenuItem) {
        event.preventDefault();

        // If currently expanded, just close
        if (item.expanded) {
            item.expanded = false;
            this.renderView();
            return;
        }
        // If not expanded but already have children loaded, just open
        else if (item.children && item.children.length) {
            item.expanded = true;
            this.renderView();
            return;
        }

        // Set loading state and re-render
        item.loading = true;
        this.renderView();

        // Load children
        item.children = await this.loadChildren(item.id);
        item.expanded = true;

        // Unset loading state and re-render
        item.loading = false;
        this.renderView();
    }
}

export interface IMenuData {
    children: IMenuItem[];
}

export interface IMenuItem {
    id: number;
    name: string;
    url: string;
    useTargetBlank: boolean;
    selected: boolean;
    expanded: boolean;
    level: number;
    loading: boolean;
    hasChildren: boolean;
    children: IMenuItem[];
}

export interface IMenuSettings {
    toggle: (event: MouseEvent, item: IMenuItem) => void;
}

customElements.define("cms-menu", CmsMenu);